/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
ngx-slider.ngx-slider {
  margin: 0;
  height: 25px;
  margin-top: 16px;
  min-width: 5em;

  .ngx-slider-bar {
    border-radius: 30px;
    height: 10px;
  }

  .ngx-slider-pointer {
    top: -11px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 2px 8px 1px rgba(27, 54, 64, 0.12);
    &:focus {
      outline: none;
      transition: $input-transition;
      box-shadow: 0 2px 8px 1px rgba(27, 54, 64, 0.12), $input-focus-box-shadow;
    }
    &:after {
      display: none;
    }
  }
}
