@import 'bootstrap/scss/functions';
$gray-100: #fafafa;
$gray-200: #f1f7fa;
$gray-300: #ecedee;
$gray-400: #e6eef3;
$gray-500: #9ab5c1;

$min-contrast-ratio: 1.5;
$panel: #0068ec;
$blue: #39c4f8;
$green: #a9de05;
$black: #000;
$yellow: #f5d63c;
$pastel-orange: #fdeec3;
$pastel-yellow: #f2ffc9;
$pastel-blue: #e1f5fe;
$pastel-grey: #eaf2ff;
$pastel-purple: #d9cef3;
$light-blue: #ebf2ff;

$light: $gray-100;
$secondary: #668a99;
$body-color: #1f363f;
$background-email: #f1f2f5;
$info: #d6eaf5;
$last-col-fix: rgba(241, 247, 250, 0.9);
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-weight: 700;
$font-weight-semibold: 600;
$form-label-font-weight: $font-weight-semibold;
$border-radius-lg: 0.5rem;
$enable-shadows: true;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.125);
$hr-opacity: 0.1;

$badge-padding-y: 0.5em;
$rounded-pill-padding-x: 0.8em;
$spacer: 1.6rem;

$breadcrumb-active-color: $body-color;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-500;

$popover-border-width: 1px;
$popover-border-color: transparent;
$popover-box-shadow: 0 1rem 0.8rem 0 rgba($black, 0.2);
$popover-border-radius: 0.5rem;
$popover-body-padding-y: 0.5rem;
$popover-body-padding-x: 0.75rem;
$popover-max-width: 400px;

$btn-box-shadow: 0;
$btn-focus-box-shadow: 0;
$btn-active-box-shadow: 0;

$input-box-shadow: 0;
$input-focus-border-color: $blue;
$input-bg: $gray-200;
$input-border-color: $gray-200;
$input-placeholder-color: $gray-500;
$input-group-addon-bg: $gray-400;

$custom-control-indicator-box-shadow: none;

$modal-lg: 900px;
$modal-backdrop-bg: $body-color;
$modal-backdrop-opacity: 0.25;
$modal-footer-border-width: 0px;
$modal-header-border-width: 0px;
$modal-content-border-width: 0px;
$modal-content-box-shadow-xs: 0 0.125rem 0.5rem rgba($black, 0.2);
$modal-content-box-shadow-sm-up: $modal-content-box-shadow-xs;

$progress-height: 0.5rem;
$progress-bg: white;
$progress-box-shadow: inset 0 0 0 1px rgba($gray-400, 1);

@import 'bootstrap/scss/variables';
$input-focus-bg: $white;

$link-decoration: underline;
$link-hover-decoration: none;

$card-border-width: 0;
$card-cap-bg: $gray-200;
$card-box-shadow: 0px 2px 24px 0px rgba(151, 151, 151, 0.2);

$nav-pills-link-active-color: $body-color;
$nav-pills-link-active-bg: $white;
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1.2rem;
$nav-link-min-width: 8rem;

$font-sizes: map-merge(
  (
    7: 14px,
  ),
  $font-sizes
);

$theme-colors: map-merge(
  (
    'gray': $gray-400,
    'input': $gray-200,
    'panel': $panel,
    'blue': $blue,
    'pastel-orange': $pastel-orange,
    'pastel-yellow': $pastel-yellow,
    'pastel-blue': $pastel-blue,
    'pastel-grey': $pastel-grey,
    'pastel-purple': $pastel-purple,
    'light-blue': $light-blue,
  ),
  $theme-colors
);
$theme-list: $theme-colors;

$table-cell-padding-y: 0.6rem;
$table-cell-padding-x: 0.5rem;
$table-bg: $gray-100;
$table-color: $secondary;
$table-border-color: $gray-300;
$table-variants: map-merge(
  $table-variants,
  (
    'secondary': $gray-200,
  )
);
$side-nav-width: 250px;
$header-height: 102px;
$navbar-brand-padding-y: 0.1875rem;

$tooltip-bg: #1c353e;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-opacity: 1;
$tooltip-border-radius: $border-radius-lg;
$tooltip-arrow-width: 21px;
$tooltip-arrow-height: 11px;
$tooltip-padding-y: 0.75rem;
$tooltip-padding-x: 1rem;
$tooltip-max-width: 300px;

$alert-padding-y: $spacer * 0.5;
$alert-padding-x: $spacer * 0.5;

$thumbnail-padding: 0;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);
$thumbnail-bg: $light;
$thumbnail-border-width: 2px;
$thumbnail-border-color: $white;

$navbar-light-color: rgba($secondary, 1);
$navbar-light-hover-color: darken($navbar-light-color, 5%);
$navbar-light-active-color: darken($navbar-light-color, 10%);
$navbar-light-disabled-color: rgba($secondary, 0.4);
$navbar-light-toggler-border-color: rgba($secondary, 0.1);

.container {
  --bs-gutter-x: 4rem;
}
