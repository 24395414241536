@import 'variables';

body {
  .material-icons-text {
    line-height: $line-height-base;
    vertical-align: middle;
  }
  .material-icons {
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    vertical-align: middle;
    display: inline-block;
    &.md-16 {
      font-size: 16px;
    }
    &.md-18 {
      font-size: 18px;
    }
    &.md-24 {
      font-size: 24px;
    }
    &.md-36 {
      font-size: 36px;
    }
    &.md-48 {
      font-size: 48px;
    }
  }

  .icon_svg {
    font-family: 'icon_svg';
    font-style: normal;
    font-size: 25px;

    &.icon_svg_home:before {
      content: '\f11f';
    }
  }

  .visiotalent-icon {
    font-family: 'visiotalent-icon';
    font-style: normal;
    font-size: 25px;

    &.visiotalent-icon-profile:before {
      content: "\e90e";
    }

    &.visiotalent-icon-camera:before {
      content: "\e90a";
    }
  }

}
