@font-face {
  font-family: 'CamptonMedium';
  src: url(../assets/fonts/camptonmedium/camptonmedium-webfont.eot);
  src: url(../assets/fonts/camptonmedium/camptonmedium-webfont.eot?#iefix) format('embedded-opentype'),
  url(../assets/fonts/camptonmedium/camptonmedium-webfont.woff) format('woff'),
  url(../assets/fonts/camptonmedium/camptonmedium-webfont.ttf)  format('truetype');
  font-style:   normal;
  font-weight:  normal;
}

@font-face {
  font-family: 'icon_svg';
  src: url('../assets/fonts/icons_fonted/icons.eot?8f899a88da9b20266fa14174a0a95b46');
  src: url('../assets/fonts/icons_fonted/icons.eot?8f899a88da9b20266fa14174a0a95b46#iefix') format('embedded-opentype'),
  url('../assets/fonts/icons_fonted/icons.woff?8f899a88da9b20266fa14174a0a95b46') format('woff'),
  url('../assets/fonts/icons_fonted/icons.ttf?8f899a88da9b20266fa14174a0a95b46') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'visiotalent-icon';
  src:  url('../assets/fonts/visiotalent-icon/visiotalenticon2.eot?inrvq');
  src:  url('../assets/fonts/visiotalent-icon/visiotalenticon2.eot?inrvq#iefix') format('embedded-opentype'),
  url('../assets/fonts/visiotalent-icon/visiotalenticon2.ttf?inrvq') format('truetype'),
  url('../assets/fonts/visiotalent-icon/visiotalenticon2.woff?inrvq') format('woff'),
  url('../assets/fonts/visiotalent-icon/visiotalenticon2.svg?inrvq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
