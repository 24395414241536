.nav {
  &.nav-pills {
    margin: -0.5em;
    .nav-item {
      @if $enable-rounded {
        border-radius: $btn-border-radius;
      } @else {
        border-radius: 0;
      }

      background-color: $gray-300;
      text-align: center;
      min-width: $nav-link-min-width;
      margin: 0.5em;

      &.active {
        background-color: $white;
        border-bottom: 4px solid $primary;
        box-shadow: $box-shadow;
        font-weight: 600;
      }
    }

    .nav-link {
      color: $body-color;
      text-decoration: none;
      height: 100%;
    }
  }
}
