.modal {
  .modal-header {
    padding: 1.5rem 4rem 0.5rem;
  }

  .modal-body {
    padding: 0.5rem 4rem 1rem;
  }

  .modal-content {
    position: relative;
  }
  .close {
    border: 0;
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .modal-dialog-scrollable .modal-content {
    overflow: visible;
    max-height: inherit;
  }
}

.modal .modal-secondary {
  .modal-content {
    background-color: $gray-200;
    .subtitle {
      font-size: 0.9375rem;
      color: $secondary;
    }
    .close {
      border: 0;
      position: absolute;
      top: -24px;
      right: 16px;
      background-color: $white;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      color: $secondary;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
      padding: 0;
      margin: 0;
      opacity: 1;
      font-size: 1.8rem;
      font-weight: normal;
      z-index: 1002;
    }
    .modal-footer {
      background-color: $white;
      box-shadow: 0px 1px 8px rgba(163, 161, 161, 0.5);
      position: relative;
    }
    .form-control,
    .ng-select-container {
      background-color: $white;
      color: $black;
    }

    app-checkable-card input:checked + .checkable-content {
      background-color: $gray-400;
    }

    .image-conversion {
      background-color: $white;
    }
  }

  @include media-breakpoint-down(lg) {
    &.modal-lg,
    &.modal-xl {
      max-width: $modal-lg;
      width: auto;
      margin: $modal-dialog-margin;
    }
  }
}
