@import 'bootstrap/scss/mixins';
[ngfDrop],
.drop {
  line-height: 1em;
  background-color: $white;
  background-clip: border-box;
  border: 1px dashed #d8d8d8;
  color: $secondary;
  display: inline-block;
  padding: 1em;
  cursor: pointer;
  @include border-radius($card-border-radius);

  .material-icons {
    color: #d8d8d8;
    line-height: 1em !important;
  }

  &.valid {
    border-color: $primary;
    color: $primary;
  }
  &.invalid {
    border-color: $danger;
    color: $danger;
  }
}
