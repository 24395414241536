@import 'variables';

body {
  .video-js {
    .vjs-menu-button-inline {
      &.vjs-slider-active,
      &:focus,
      &:hover {
        width: 10em;
      }
    }
    &.vjs-no-flex .vjs-menu-button-inline {
      width: 10em;
    }
    .vjs-controls-disabled .vjs-big-play-button {
      display: none !important;
    }
    .vjs-control {
      width: 3em;
    }
    .vjs-menu-button-inline:before {
      width: 1.5em;
    }
  }

  .vjs-menu-button-inline .vjs-menu {
    left: 3em;
  }

  .vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
    display: block;
  }

  .video-js {
    &.vjs-ended .vjs-big-play-button,
    &.vjs-paused .vjs-big-play-button {
      display: block;
    }
    .vjs-load-progress div {
      display: none !important;
    }

    &:hover {
      .vjs-big-play-button {
        background-color: none !important;
      }
    }
  }

  .vjs-seeking .vjs-big-play-button,
  .vjs-waiting .vjs-big-play-button {
    display: none !important;
  }

  .video-js {
    .vjs-mouse-display:after,
    .vjs-play-progress:after {
      padding: 0 0.4em 0.3em;
    }
    &.vjs-ended {
      .vjs-loading-spinner {
        display: none;
      }
      .vjs-big-play-button {
        display: block !important;
      }
    }
    *,
    &:after,
    &:before {
      box-sizing: inherit;
      font-size: inherit;
      color: inherit;
      line-height: inherit;
    }
    &.vjs-fullscreen {
      width: 100% !important;
      height: 100% !important;
      .vjs-tech {
        width: 100% !important;
        height: 100% !important;
      }
    }
    font-size: 14px;
    .vjs-control {
      color: inherit;
    }
    .vjs-menu-button-inline:hover,
    &.vjs-no-flex .vjs-menu-button-inline {
      width: 8.35em;
    }
    .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
      height: 3em;
      width: 6.35em;
    }
    .vjs-control {
      &:focus:before,
      &:hover:before {
        text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff;
      }
    }
    .vjs-spacer {
      display: flex;
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }
    .vjs-time-control {
      display: flex;
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-box-flex: 0 1 auto;
      -moz-box-flex: 0 1 auto;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: auto;
      padding: 0px;
      &.vjs-time-divider {
        width: 14px;
        div {
          width: 100%;
          text-align: center;
        }
      }
      &.vjs-current-time {
        margin-left: 1em;
      }
      .vjs-current-time-display,
      .vjs-duration-display {
        width: 100%;
      }
      .vjs-current-time-display {
        text-align: right;
      }
      .vjs-duration-display {
        text-align: left;
      }
    }
    .vjs-play-progress:before,
    .vjs-progress-control .vjs-play-progress:before,
    .vjs-remaining-time {
      display: none;
    }
    .vjs-volume-level {
      &:after,
      &:before {
        display: none;
      }
    }
    &.vjs-live .vjs-time-control {
      &.vjs-current-time,
      &.vjs-duration,
      &.vjs-time-divider {
        display: none;
      }
    }
    &.vjs-no-flex .vjs-time-control {
      &.vjs-remaining-time {
        display: none;
      }
      display: table-cell;
      width: 4em;
    }
    .vjs-progress-control {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 0.5em;
      top: -0.5em;
      .vjs-load-progress,
      .vjs-play-progress {
        .vjs-time-tooltip {
          display: none;
        }
        height: 100%;
      }
      .vjs-progress-holder {
        height: 100%;
        margin: 0;
      }
      &:hover {
        height: 1.5em;
        top: -1.5em;
      }
    }
    .vjs-control-bar {
      transition: opacity 0.1s ease 0s;
      opacity: 1;
    }
    &.not-hover.vjs-has-started {
      &.vjs-paused {
        &.vjs-user-active .vjs-control-bar,
        &.vjs-user-inactive .vjs-control-bar {
          visibility: visible;
          opacity: 0;
          -webkit-backface-visibility: hidden;
          //transform: translateY(3em);
          transition: opacity 0.3s ease 0s;
        }
      }
      &.vjs-playing {
        &.vjs-user-active .vjs-control-bar,
        &.vjs-user-inactive .vjs-control-bar {
          visibility: visible;
          opacity: 0;
          -webkit-backface-visibility: hidden;
          //transform: translateY(3em);
          transition: opacity 0.3s ease 0s;
        }
      }
    }
    &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
      visibility: visible;
      opacity: 0;
      -webkit-backface-visibility: hidden;
      //transform: translateY(3em);
      transition: opacity 0.3s ease 0s;
    }
    &.not-hover.vjs-has-started {
      &.vjs-paused {
        &.vjs-user-active .vjs-progress-control,
        &.vjs-user-inactive .vjs-progress-control {
          height: 1em;
          top: -1em;
          pointer-events: none;
          transition: height 1s, top 1s;
        }
      }
      &.vjs-playing {
        &.vjs-user-active .vjs-progress-control,
        &.vjs-user-inactive .vjs-progress-control {
          height: 1em;
          top: -1em;
          pointer-events: none;
          transition: height 1s, top 1s;
        }
      }
    }
    &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
      height: 1em;
      top: -1em;
      pointer-events: none;
      transition: height 1s, top 1s;
    }
    &.not-hover.vjs-has-started {
      &.vjs-paused {
        &.vjs-user-active.vjs-fullscreen .vjs-progress-control,
        &.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
          opacity: 0;
          transition: opacity 1s ease 1s;
        }
      }
      &.vjs-playing {
        &.vjs-user-active.vjs-fullscreen .vjs-progress-control,
        &.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
          opacity: 0;
          transition: opacity 1s ease 1s;
        }
      }
    }
    &.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
      opacity: 0;
      transition: opacity 1s ease 1s;
    }
    background-color: transparent;
    .vjs-tech {
      background-color: black;
    }
    &.vjs-live .vjs-live-control {
      margin-left: 1em;
    }
    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      margin-left: -1.5rem;
      margin-top: -1.5rem;
      width: 3rem;
      height: 3rem;
      line-height: 3rem;
      border: none;
      border-radius: 50%;
      font-size: 2.5rem;
      background-color: $primary;
      opacity: 0.7;
      color: #fff;
      transition: border-color 0.4s, outline 0.4s, background-color 0.4s;
    }
    .vjs-menu-button-popup .vjs-menu {
      left: -3em;
      .vjs-menu-content {
        background-color: transparent;
        width: 22em;
        left: -10.5em;
        padding-bottom: 0.5em;
        font-size: 0.9em;
      }
      .vjs-menu-item,
      .vjs-menu-title {
        background-color: #151b17;
        margin: 0.3em 0;
        padding: 0.5em;
        border-radius: 0.3em;
      }
      .vjs-menu-item.vjs-selected {
        background-color: $primary;
        color: white;
      }
    }
    &:hover .vjs-big-play-button {
      opacity: 1;
    }
    .vjs-big-play-button {
      &:focus,
      &:active {
        opacity: 1;
      }
    }
    .vjs-loading-spinner {
      border-color: $primary;
    }
    .vjs-control-bar2 {
      background-color: #000000;
    }
    .vjs-control-bar {
      background-color: rgba(0, 0, 0, 0.3) !important;
      color: #ffffff;
      font-size: 14px;
    }
    .vjs-play-progress,
    .vjs-volume-level {
      background-color: $primary;
    }
    .vjs-progress-control {
      height: 0.9em;
      top: -0.9em;
      transition: all 0.3s ease;
    }
  }

  .vjs-marker {
    z-index: 10;
  }

  .vjs-tip {
    font-size: 14px;
  }
  .video-js {
    .vjs-text-track-display {
      bottom: 1em;
      > div > div {
        background-color: rgba(255, 255, 255, 0.7) !important;
        box-shadow: 0px 1px 3px 0px rgba(31, 55, 64, 0.12) !important;
        border-radius: 0px !important;
        padding: 8px !important;
        > div {
          background-color: transparent !important;
          font-size: 14px !important;
          font-weight: $font-weight-semibold;
          font-family: $font-family-sans-serif !important;
          color: $body-color !important;
          letter-spacing: 0px !important;
          box-shadow: none !important;
        }
      }
    }
  }

  .vjs-texttrack-settings,
  .vjs-record .vjs-device-button.vjs-control {
    display: none;
  }

  .vjs-marker {
    position: absolute;
    left: 0;
    bottom: 0em;
    opacity: 1;
    height: 100%;
    transition: opacity 0.2s ease;
    -webkit-transition: opacity 0.2s ease;
    -moz-transition: opacity 0.2s ease;
    z-index: 100;
    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.3, 1.3);
      -moz-transform: scale(1.3, 1.3);
      -o-transform: scale(1.3, 1.3);
      -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3);
    }
  }

  .vjs-tip {
    visibility: hidden;
    display: block;
    opacity: 0.8;
    padding: 5px;
    font-size: 10px;
    position: absolute;
    bottom: 14px;
    z-index: 100000;
    .vjs-tip-arrow {
      background: url(data:image/gif;base64,R0lGODlhCQAJAIABAAAAAAAAACH5BAEAAAEALAAAAAAJAAkAAAIRjAOnwIrcDJxvwkplPtchVQAAOw==) no-repeat top
        left;
      bottom: 0;
      left: 50%;
      margin-left: -4px;
      background-position: bottom left;
      position: absolute;
      width: 9px;
      height: 5px;
    }
    .vjs-tip-inner {
      border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      padding: 5px 8px 4px 8px;
      background-color: black;
      color: white;
      max-width: 200px;
      text-align: center;
    }
  }

  .vjs-break-overlay {
    visibility: hidden;
    position: absolute;
    z-index: 100000;
    top: 0;
    .vjs-break-overlay-text {
      padding: 9px;
      text-align: center;
    }
  }
}
