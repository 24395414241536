/* You can add global styles to this file, and also import other style files */

@import 'styles/variables';
@import 'styles/bootstrap';
@import 'styles/base';
@import 'styles/fonts';
@import 'styles/icons';
@import 'styles/ng-select';
@import 'styles/toggle-switch';
@import 'styles/popover';
@import 'styles/modal';
@import 'styles/slider';
@import 'styles/toaster';
@import 'styles/nav';
@import 'styles/image-loader';
@import 'styles/angular-file';
@import 'styles/videojs';
@import 'styles/charts';
@import 'styles/table';
@import 'styles/editor-html';
@import 'styles/form';

