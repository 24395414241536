cc-table.last-col-fix,
table.last-col-fix {
  tr:hover td:last-child {
    //--bs-table-accent-bg: inherit;
  }
  table th {
    font-weight: 600;
    font-size: 0.9em;
  }
  .checkall-dropdown-button {
    font-size: 1.5em;
  }

  tr {
    td:last-child,
    th:last-child {
      right: 0px;
      position: sticky;
      vertical-align: middle;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 1px;
        width: calc(100% - 1px);
        height: 100%;
        box-shadow: rgba($gray-300, 1) -1px 0px 0px 0px;
      }
    }

    .text-truncate {
      max-width: 200px;
    }
  }
}
