.popover {
  position: absolute;

  .popover-arrow {
    position: absolute;
  }
}

.tooltip {
  position: absolute;

  .tooltip-arrow {
    position: absolute;
  }
}
