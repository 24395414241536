$color-selected: #f5faff;

.ng-select {
  color: $input-color;
  width: 100%;

  &.ng-select-opened {
    > .ng-select-container {
      background: $input-bg;
      border-color: $input-border-color;

      .ng-arrow {
        top: -4px;
        border-color: transparent transparent $gray-500;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent $gray-400;
        }
      }
    }
  }

  &.ng-select-focused {
    > .ng-select-container {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      transition: $input-transition;

      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
      } @else {
        box-shadow: $input-focus-box-shadow;
      }
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: #f9f9f9;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    background-color: $input-bg;
    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    min-height: $input-height;
    line-height: $input-line-height;
    align-items: center;

    .ng-value-container {
      align-items: center;
      padding-left: 10px;

      .ng-placeholder {
        color: $input-placeholder-color;
      }
    }
  }

  &.is-invalid {
    .ng-select-container {
      border-color: #dc3545;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: 36px;

      .ng-value-container {
        .ng-input {
          left: 0;
          padding-left: 10px;
          padding-right: 50px;
          top: 5px;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: #f9f9f9;
        border: 1px solid #e3e3e3;

        .ng-value-label {
          padding: 0px 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-left: 7px;
        padding-top: 7px;

        .ng-value {
          font-size: 0.8em;
          margin-right: 10px;
          margin-bottom: 7px;
          background-color: $primary;
          position: relative;
          color: white;
          padding: 1px 10px;
          border-radius: 4px;

          &.ng-value-disabled {
            background-color: #f9f9f9;
            border: 1px solid #e3e3e3;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 0px 5px 0px 5px;
          }

          .ng-value-icon {
            position: absolute;
            background-color: black;
            display: none;
            font-size: 1.3em;
            line-height: 0.65em;
            vertical-align: middle;
            opacity: 0.8;
            border-radius: 100%;
            padding: 3px;
            transition: opacity 0.15s linear;
            top: -7px;
            right: -7px;
            z-index: 1;

            &:hover {
              opacity: 1;
            }
          }

          &:hover {
            .ng-value-icon {
              display: block;
            }
          }
        }

        .ng-input {
          padding-bottom: 3px;
          padding-left: 3px;
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $gray-500;

    .ng-clear {
      &:hover {
        color: #d0021b;
      }
    }
  }

  .ng-spinner-zone {
    padding-right: 5px;
    padding-top: 5px;
  }

  .ng-arrow-wrapper {
    padding-right: 5px;
    width: 25px;

    .ng-arrow {
      top: -2px;
      border-color: $gray-500 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;

      &:hover {
        border-top-color: $gray-400;
      }
    }
  }
}

.input-group > .ng-select {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;

  &:not(:last-child) {
    .ng-select-container {
      @include border-radius(0);
    }
  }

  &:not(:first-child) {
    .ng-select-container {
      @include border-radius(0);
    }
  }
}

.ng-dropdown-panel {
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  border-radius: $input-border-radius;

  &.ng-select-bottom {
    top: 100%;
    margin-top: 5px;
  }

  &.ng-select-top {
    bottom: 100%;
    margin-bottom: 5px;
  }

  .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      font-weight: bold;
      user-select: none;
      cursor: default;
      padding: 8px 10px;

      &.ng-select-disabled {
        color: rgba(0, 0, 0, 0.54);
      }

      &.ng-option-marked {
        background-color: $input-bg;
        color: #333;
      }

      &.ng-option-selected {
        color: #333;
        background-color: $color-selected;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.87);
      padding: 8px 10px;

      &.ng-option-selected {
        font-weight: 700;
        color: #333;
        background-color: $color-selected;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $input-bg;
        color: #333;
      }

      &.ng-option-disabled {
        color: #cccccc;
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;
      }
    }
  }
}

.modal-select {
  .ng-select-container {
    cursor: pointer !important;

    &:hover {
      background-color: #fff !important;
      border-radius: 5px;
    }
  }

  .ng-input {
    input {
      cursor: pointer !important;
    }
  }

  .ng-dropdown-panel-items {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
  }
}

.questions-generator-header {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-left: 20px;

    &::before {
      font-family: 'Material Icons';
      content: '\e8b6';
      margin-right: 15px;
      color: $gray-500;
      direction: ltr;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .ng-select {
    .ng-arrow-wrapper {
      display: none;
    }

    .ng-select-container {
      background-color: #fff;

      .ng-value-container .ng-input > input {
        cursor: text;
      }
    }
  }

  .ng-dropdown-panel .scroll-host {
    box-shadow: 5px 10px 10px grey;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 0;
  }
}

.ng-select-white {
  .ng-select-container {
    background-color: #fff;
  }
}
