div.NgxEditor__MenuBar {
  padding: 0;
}

div.NgxEditor {
  border: none;
}

.edit-email-overlay {
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
  display: flex;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);

    i {
      display: flex;
    }
  }

  i {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 48px;
  }
}
