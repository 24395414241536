@import 'src/styles/variables';

html,
body {
  height: 100%;
  overflow: hidden;
}

select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: bottom 50% right 10px;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiID8+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAiIGhlaWdodD0iNyI+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwXzAiPjxyZWN0IHg9Ii01OTMuODc5ODgiIHk9Ii0zMTguOTU5OTYiIHdpZHRoPSIxMTc3LjYiIGhlaWdodD0iODUxLjkyMDA0IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L2NsaXBQYXRoPjwvZGVmcz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcF8wKSI+PHBhdGggZmlsbD0icmdiKDE0NCwxNjEsMTgxKSIgc3Ryb2tlPSJub25lIiB0cmFuc2Zvcm09Im1hdHJpeCgtMC45MiAtOC4wNDI5ZS0wOCA4LjA0MjllLTA4IC0wLjkyIDkuNjM5NjUgNi43MTk3MykiIGQ9Ik05LjgzNDk4IDQuODY5ODFMOC4zNTk3NCA2LjMzMDc1TDQuOTE3NDkgMi45MjE4OEwxLjQ3NTI1IDYuMzMwNzVMMCA0Ljg2OTgxTDQuOTE3NDkgMEw5LjgzNDk4IDQuODY5ODFaIi8+PC9nPjwvc3ZnPg==);

  ::-ms-expand {
    display: none;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
}

a.btn {
  text-decoration: inherit;
}

:focus {
  outline: none;
}

.btn-group,
.btn-group-vertical {
  .btn:not(.active) {
    background-color: $white;
    border-color: $secondary;
    color: $secondary;
  }

  &.is-invalid {
    .btn:not(.active) {
      background-color: $white;
      border-color: $danger;
      color: $danger;
    }
  }
}

.form-group {
  *:not(.custom-control) label,
  > label {
    font-weight: 600;
  }
}

.breadcrumb {
  color: $gray-500;
}

button.btn.btn-img {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  transition: opacity 0.15s ease-in-out;

  &:disabled {
    opacity: 0.15;
  }

  &:not(:disabled):not(.disabled) {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.fit-contain {
  object-fit: contain;
}

@mixin custom-validation-state($state, $color) {
  .form-group {
    &:#{$state},
    &.is-#{$state} {
      > .#{$state}-feedback,
      > .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .validation-control {
    &:#{$state},
    &.is-#{$state} {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }
}

@include custom-validation-state('valid', $form-feedback-valid-color);
@include custom-validation-state('invalid', $form-feedback-invalid-color);

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-auto {
  overflow: auto;
}

.btn {
  @each $color, $value in $theme-colors {
    &.btn-#{$color} .btn-spinner {
      border-color: color-contrast($value);
      border-right-color: transparent;
    }
  }

  .btn-spinner {
    align-self: center !important;
    font-family: sans-serif;
    font-weight: 100;
    -webkit-animation: 1.25s linear infinite three-quarters;
    animation: 1.25s linear infinite three-quarters;
    border: 3px solid black;
    border-right-color: transparent;
    border-radius: 100%;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    text-indent: -9999px;
    width: 18px;
    height: 18px;

    &:not(:required) {
      margin-left: -18px;
      opacity: 0;
      transition: 0.4s margin ease-out, 0.2s opacity ease-out;
    }
  }

  &.is-loading .btn-spinner {
    transition: 0.2s margin ease-in, 0.4s opacity ease-in;
    margin-left: 8px;
    opacity: 1;
  }

  @-webkit-keyframes three-quarters {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes three-quarters {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.disabled .btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: initial;
  cursor: default;
  opacity: 0.5;
}

.card {
  @include box-shadow($card-box-shadow);
}

dd {
  margin-bottom: 1rem;
}

.pagination {
  a {
    text-decoration: none;
  }
}

iframe {
  border: none;
}

.disable-pointer {
  pointer-events: none !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.tooltip-inner {
  text-overflow: ellipsis;
  overflow: hidden;
}

cc-user-thumbnail {
  vertical-align: middle;
  font-weight: $font-weight-semibold;
}

cc-table .navbar-brand {
  font-weight: $font-weight-semibold;
}

bs-dropdown-container {
  z-index: $zindex-dropdown;
}

.btn-input {
  color: $gray-500;
  &.disabled,
  &:disabled {
    color: $text-muted;
  }
}

a.badge {
  text-decoration: none;
}

// there is no translation for 'Custom Range' string :(
.bs-datepicker-predefined-btns {
  .btn:last-child {
    display: none;
  }

  button:hover {
    color: white;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.card {
  .card-title {
    font-weight: 700;
  }
  .card-text {
    font-size: 0.8em;
  }
}

.table-secondary.text-secondary {
  color: $gray-700 !important;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
  border-top-color: inherit;
}
.table > :not(caption) > * > * {
  border-bottom-width: 1px;
  border-top-width: 1px;
}

.table tr:hover .show-on-hover:not(:focus) {
  opacity: inherit;
}

.table tr .show-on-hover:not(:focus) {
  opacity: 0;
}

.block-missing-video {
  p {
    font-size: 14px;
    color: $secondary;
  }

  img {
    width: 70px;
    height: 70px;
    margin: auto;
  }
}
